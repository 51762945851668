.entry {
    padding: 2%;
    width: 40%;
}
@media (max-width: 50rem) {
    .entry {
        width: 90%;
    }
}

.entry-title {
    margin-top: 10px;
    margin-bottom: 3px;
}
.entry-subtitle {
    height: 2rem;
    margin-bottom: 30px;
}
.entry-links {
    margin-bottom: 15px;
}

.entry-link {
    padding: 10px;
    flex-basis: 20%;

    transition: box-shadow 0.25s ease-in-out;
    &:hover {
        box-shadow: 1px 2px 1px rgba(0, 0, 255, 0.07) inset,
                    -1px 2px 1px rgba(0, 0, 255, 0.07) inset,
                    1px -1px 1px rgba(0, 0, 255, 0.05) inset,
                    -1px -1px 1px rgba(0, 0, 255, 0.05) inset;
    }
}
