* {
    margin: 0;
    padding: 0;
}

body {
    width: 95%;
    max-width: 900px;
    margin: auto;

    background-color: rgb(230, 230, 235);
    color: black;

    text-align: center;
    font-family: "Lato", sans-serif;
    font-size: 1.2rem;
}

a {
    text-decoration: none;
    color: inherit;
}


.flex {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}
.flex-element {
    margin: 1%;
    flex-grow: 1;
}


.box {
    border: 1px rgba(60, 60, 60, 0.3) solid;
    border-radius: 20px;

    background-color: white;
}



h1 {
    font-size: 4.8rem;
}
h2 {
    font-size: 1.5rem;
    font-weight: normal;
}
h3 {
    font-size: 2.4rem;
    font-weight: normal;
}
@media (max-width: 600px) {
    h2 {
        font-size: 1.3rem;
    }
    h3 {
        font-size: 2rem;
    }
    body {
        font-size: 1.1rem;
    }
}
@media (max-width: 450px) {
    h1 {
        font-size: 4rem;
    }
    h2 {
        font-size: 1.1rem;
    }
    h3 {
        font-size: 1.7rem;
    }
    body {
        font-size: 1rem;
    }
}
